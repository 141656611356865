/** @jsx jsx */
import { jsx, Grid, Box, Themed } from 'theme-ui'
import { Link } from 'gatsby'
import { keyframes } from '@emotion/core'

const blinkFont = keyframes`
  from {
    font-style: normal;
  }
  to {
    font-style: italic;
  }
`

const linkStyle = {
  fontStyle: 'italic',
  '&:hover': {
    animation: `${blinkFont} 1.25s linear infinite`,
  },
  '&.active': {
    fontStyle: 'italic',
  },
}

export const ExternalLink = (props) => (
  <Themed.a
    sx={{ ...linkStyle }}
    {...props}
    target="_blank"
    rel="noopener noreferrer"
  />
)

export const StyledLink = (props) => (
  <Themed.a
    as={Link}
    activeClassName="active"
    sx={{ ...linkStyle }}
    {...props}
  />
)

export const NavLink = (props) => (
  <Themed.a
    as={Link}
    activeClassName="active"
    sx={{
      color: 'var(--main-color)',
      transition: 'color 1s ease',
      '&:hover': {
        animation: `${blinkFont} 1.25s linear infinite`,
      },
      '&.active': {
        fontStyle: 'italic',
      },
    }}
    {...props}
  />
)
