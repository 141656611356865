/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { keyframes } from '@emotion/core'

const blink = keyframes`
  25% {
    opacity: 1%;
  }
  30% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  75% {
    opacity: 1%;
  }
`

export const LiveIndicator = (props) => (
  <Box
    sx={{
      position: 'absolute',
      left: 'calc(100% + 0.75em)',
      top: '-1px',
      border: '1px solid red',
      color: 'red',
      borderRadius: '2em',
      display: 'flex',
      alignItems: 'center',
      p: 2,
      fontStyle: 'normal',
      lineHeight: 1,
      fontSize: 1,
      flexGrow: 0,
    }}
    {...props}
  >
    <svg
      viewBox="0 0 10 10"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '1em',
        height: '1em',
        mr: 2,
        animation: `${blink} 1.25s linear infinite`,
      }}
    >
      <circle cx="5" cy="5" r="5" />
    </svg>
    <span sx={{ mr: 1 }}>LIVE</span>
  </Box>
)
