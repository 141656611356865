/** @jsx jsx */
import { jsx, Flex, Grid, Button } from 'theme-ui'
import { Fragment, useEffect, useState } from 'react'
import ky from 'ky'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'
import Div100vh from 'react-div-100vh'

import { Logo } from '@components/Logo'
import { useStore } from '@stores/3d'
import { NavLink, ExternalLink, StyledLink } from '@components/Links'
import { LiveIndicator } from '@components/LiveIndicator'

const buttonStyle = {
  display: 'flex',
  p: 0,
  m: 0,
  '&:not(:last-of-type)': {
    borderBottom: '1px solid black',
  },
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}

const Header = () => {
  const [navOpen, setNav] = useState(false)
  const live = useStore((s) => s.live)
  const setStation = useStore((s) => s.actions.setStation)

  useEffect(() => {
    const fetchLiveStatus = async () => {
      try {
        const { station } = await ky(
          'https://radioradio-fm-backend.herokuapp.com/api/v1/station'
        ).json()
        if (station) {
          setStation({
            color: station.color,
            live: station.live ?? false,
            redirect: station.redirect ?? false,
            clubScheduleText: station.club_schedule,
            radioScheduleText: station.radio_schedule,
            archiveText: station.archive,
            archiveEmbed: station.archive_embed,
          })
        }
      } catch (error) {
        console.error('Could not get live status', error)
      }
    }
    setTimeout(() => {
      fetchLiveStatus()
    }, 1000)
  }, [setStation])

  return (
    <Fragment>
      <Flex
        as="nav"
        sx={{
          alignItems: 'center',
          justifyContent: ['space-between', 'flex-start'],
          bg: 'black',
          color: 'var(--main-color)',
          transition: 'color 1s ease',
          p: 3,
          fontSize: 3,
        }}
      >
        <NavLink
          to="/"
          sx={{
            display: ['flex', 'none'],
            position: 'relative',
          }}
        >
          <Logo sx={{ height: '1.5em' }} />
          {live && <LiveIndicator />}
        </NavLink>
        <div
          sx={{
            display: ['none', 'flex'],
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <NavLink
            to="/"
            sx={{
              display: 'flex',
              position: 'relative',
            }}
          >
            <Logo sx={{ height: '1.5em' }} />
            {live && <LiveIndicator />}
          </NavLink>
          <NavLink to="/playlists">Playlists</NavLink>
          <NavLink to="/mixes">Archive</NavLink>
          <ExternalLink
             sx={{ px: 0, fontStyle: 'normal' }}
             href="https://pizzapizza.pizza"
           >
             Food
           </ExternalLink>
          <NavLink to="/contact">Contact</NavLink>
        </div>

        <Button
          onClick={() => {
            setNav((state) => !state)
          }}
          sx={{
            m: 0,
            p: 0,
            justifySelf: 'flex-end',
            display: ['block', 'none'],
            bg: 'transparent',
            fontFamily: 'body',
            color: 'var(--main-color)',
            transition: 'color 1s ease',
            outline: 'none',
            '&:hover': {
              fontStyle: 'italic',
              cursor: 'pointer',
            },
          }}
        >
          Menu
        </Button>
      </Flex>
      <DialogOverlay isOpen={navOpen} onDismiss={() => setNav(false)}>
        <DialogContent
          sx={{
            m: 0,
            p: 0,
            bg: 'var(--main-color)',
            transition: 'background 1s ease',
            width: '100%',
            minHeight: '100%',
          }}
        >
          <Div100vh>
            <Grid
              sx={{
                justifyContent: 'center',
                textAlign: 'center',
                fontSize: 4,
                gridTemplateRows: '1fr 1fr 1fr 1fr 1fr',
                gridTemplateColumns: '1fr',
                gap: 0,
                height: '100%',
              }}
            >
              <Button
                onClick={() => {
                  setNav((state) => !state)
                }}
                sx={{
                  bg: 'transparent',
                  color: 'black',
                  fontFamily: 'body',
                  borderRadius: 0,
                  outline: 'none',
                  '&:hover': {
                    fontStyle: 'italic',
                    cursor: 'pointer',
                  },
                  ...buttonStyle,
                  borderBottom: '1px solid black',
                }}
              >
                Close
              </Button>
              <StyledLink
                to="/"
                sx={{
                  display: 'flex',
                  position: 'relative',
                  ...buttonStyle,
                }}
              >
                Radio
                {live && (
                  <LiveIndicator
                    sx={{ position: 'relative', left: 'auto', ml: 3 }}
                  />
                )}
              </StyledLink>
              <StyledLink sx={{ ...buttonStyle }} to="/playlists">
                Playlists
              </StyledLink>
              <StyledLink sx={{ ...buttonStyle }} to="/mixes">
                Archive
              </StyledLink>
              <ExternalLink
                 sx={{ ...buttonStyle }}
                 href="https://pizzapizza.pizza"
               >
                 Food
               </ExternalLink>
              <StyledLink sx={{ ...buttonStyle }} to="/contact">
                Contact
              </StyledLink>
            </Grid>
          </Div100vh>
        </DialogContent>
      </DialogOverlay>
    </Fragment>
  )
}

export default Header
