import create from 'zustand'
import produce from 'immer'
import pull from 'lodash/pull'
import sanitizeHtml from 'sanitize-html'

const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get)

const sanitize = (text: string) => sanitizeHtml(text, {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['del'])
})

export const MAX_HEARTS = 100

export const useStore = create(
  immer((set) => ({
    hearts: [],
    inactiveHearts: new Array(MAX_HEARTS).fill(0).map((_, i) => i),
    clients: [],
    color: '#DCDCDC',
    streamcount: 0,
    donateCount: 0,
    showChat: false,
    streamURL: 'https://stream.radioradio.radio/radioradio/live/playlist.m3u8',
    testStreamURL:
      'https://stream.radioradio.radio/radioradio/test/playlist.m3u8',
    rerunStreamURL:
      'https://stream.radioradio.radio/radioradio/offline/playlist.m3u8',
    socialLinks: [],
    clubSchedule: [],
    clubText: '',
    radioText: '',
    showLinksOffline: false,
    listenForHearts: true,
    live: false,
    redirect: false,
    rerun: false,
    links: undefined,
    messages: [],
    actions: {
      addMessage: (message: { content: string; name: string }) => {
        set((state) => {
          if (state.messages.length > 80) {
            state.messages.shift()
          }
          state.messages.push(message)
        })
      },
      addHeart: () => {
        set((state) => {
          if (state.listenForHearts && state.hearts.length < MAX_HEARTS) {
            const inactive = state.inactiveHearts.shift()
            state.hearts.push(inactive)
          }
        })
      },
      clearHeart: (id: string) => {
        set((state) => {
          pull(state.hearts, id)
          state.inactiveHearts.push(id)
        })
      },
      setStation: (data: {
        live?: boolean;
        color?: string;
        redirect?: boolean;
        clubScheduleText?: string;
        radioScheduleText?: string;
        archiveText?: string;
        archiveEmbed?: string;
      }) => {
        set((state) => {
          state.live = data.live ?? state.live;
          state.redirect = data.redirect ?? state.redirect;
          state.clubScheduleText = sanitize(data.clubScheduleText) || state.clubScheduleText;
          state.radioScheduleText = sanitize(data.radioScheduleText) || state.radioScheduleText;
          state.archiveText = sanitize(data.archiveText) || state.archiveText;
          state.archiveEmbed = data.archiveEmbed || state.archiveEmbed;
          state.color = state.live
            ? data.color || state.color || '#DCDCDC'
            : '#DCDCDC'

          document.documentElement.style.setProperty('--main-color', state.color)
          document.documentElement.style.setProperty('background-color', state.color)
        })
      },
    },
  }))
)
