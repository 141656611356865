import React from 'react'
import { useStore } from '@stores/3d'

interface ActionCableProviderProps {
  children: React.ReactElement
}

export default function ActionCableProvider ({ children }: ActionCableProviderProps) {
  React.useEffect(() => {
    (async () => {
      const actionCable = await import('actioncable')
      const cable = actionCable.createConsumer('wss://radioradio-fm-backend.herokuapp.com/cable')
      cable.subscriptions.create('StationChannel', {
        received: (data) => {
          if (data?.station) {
            useStore.getState().actions.setStation({
              color: data.station.color,
              live: data.station.live === '1',
              redirect: data.station.redirect === '1',
              clubScheduleText: data.station.club_schedule,
              radioScheduleText: data.station.radio_schedule,
              archiveText: data.station.archive,
              archiveEmbed: data.station.archive_embed,
            })
          }
        }
      })
    })()
  }, [])

  return children
}
