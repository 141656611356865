/** @jsx jsx */
import { jsx, Grid, Flex } from 'theme-ui'
import type { GridProps } from '@theme-ui/components'

import { StyledLink, ExternalLink } from '@components/Links'
import { useUIStore } from '@stores/ui'
import WeTransferLogo from './WeTransferLogo'

const Footer = (props: GridProps) => {
  const slug = useUIStore((state) => state.playerSlug)

  return (
    <Grid
      as="footer"
      sx={{
        gridTemplateColumns: [
          '1fr',
          '1fr',
          '1fr',
          'max-content max-content max-content',
        ],
        gridTemplateRows: ['1fr 1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr', '1fr'],
        borderTop: '1px solid black',
        justifyContent: 'space-between',
        py: 3,
        px: [2, 3],
        fontSize: [1, 2],
        paddingBottom: !!slug ? 'calc(60px + 16px)' : 3,
      }}
      {...props}
    >
      <Flex
        sx={{
          justifyContent: ['center', 'flex-start'],
          'a:not(:last-child)': {
            mr: 2,
          },
        }}
      >
        <ExternalLink href="https://www.facebook.com/radioradioradioradioradio/">
          Facebook
        </ExternalLink>
        <ExternalLink href="https://www.instagram.com/radioradio.fm/">
          Instagram
        </ExternalLink>
        <ExternalLink href="https://www.mixcloud.com/radioradioradioradio/">
          Mixcloud
        </ExternalLink>
        <ExternalLink href="https://open.spotify.com/playlist/1hgZd0Rtn01QARvcsTzj3t?si=LN8wJHFjT02cc6_8D45SHw">
          Spotify
        </ExternalLink>
        <ExternalLink href="https://www.youtube.com/channel/UC5yWmryZnGQ-Bv3RBhntDlA">
          YouTube
        </ExternalLink>
      </Flex>
      <WeTransferLogo />
      <Flex
        sx={{
          justifyContent: ['center', 'flex-start'],
          'a:not(:last-child)': {
            mr: 2,
          },
        }}
      >
        <ExternalLink href="https://news.radioradio.radio">
          Newsletter
        </ExternalLink>
        <StyledLink to="/partnerships">Partnerships</StyledLink>
      </Flex>
    </Grid>
  )
}

export default Footer
