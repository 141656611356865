/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ExternalLink } from '@components/Links'

export default function WeTransferLogo () {
  return (
    <ExternalLink
      href="https://wetransfer.com/"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: ['center', 'flex-start'],
      }}
    >
      Powered by
      <svg
        viewBox="0 0 190 28"
        sx={{ width: 100, display: 'inline-block', ml: 2, mb: '2px' }}
      >
        <g fill="#000" fillRule="nonzero">
          <path d="M38.282 1.094c-1.44 1.503-1.866 2.303-2.898 4.573l-9.513 21.817-6.73-15.285-6.648 15.285-9.595-21.8C1.916 3.413 1.474 2.677 0 1.093h12.166c-.917.768-1.392 1.764-1.392 3.005 0 .882.147 1.502.622 2.646l3.635 8.654 2.686-6.45-1.507-3.495c-.884-2.008-1.506-3.266-2.39-4.36h12.165c-1.178.768-1.768 1.764-1.768 3.005 0 .882.147 1.502.622 2.646l3.717 8.654 3.668-8.654c.475-1.144.655-1.764.655-2.597 0-1.094-.77-2.417-1.654-3.037h7.057v-.017zM50.923 20.527c-1.179 5.095-4.012 6.777-8.121 6.777-4.7 0-9.104-3.413-9.104-9.815 0-6.156 3.897-10.516 9.005-10.516 3.34 0 8.04 1.715 8.04 8.981H39.641c.77 3.887 3.308 5.895 6.828 5.895 1.801-.016 3.062-.342 4.454-1.322zm-11.494-7.12v.473h4.846c0-3.657-.802-4.8-2.358-4.8-1.571 0-2.488 1.584-2.488 4.327z" />
          <path d="M73.191 7.724c-3.045-2.89-6.058-4.393-8.383-4.393v16.167c0 3.266.851 5.242 3.078 7.25H54.001c2.243-2.008 3.078-4 3.078-7.25V3.331c-2.309 0-5.469 1.503-8.383 4.393L50.17 0c.998.768 2.832 1.094 4.879 1.094h11.756c2.063 0 3.897-.326 4.88-1.094l1.506 7.724z" />
          <path d="M81.64 26.749H69.95c1.44-1.682 2.063-2.793 2.063-5.455V15.35c0-2.09-.295-3.07-1.687-4.36l-.802-.735 8.825-3.298v4.572c.852-2.645 2.162-4.572 4.34-4.572 1.768 0 2.898 1.24 2.898 3.07 0 1.943-1.212 3.298-2.899 3.298-.36-.849-1.244-1.24-2.063-1.24-.655 0-1.293.179-1.62.505v8.476c-.017 2.825.458 3.592 2.635 5.683zM105.775 23.384c-.442 2.303-2.357 3.92-4.961 3.92-2.21 0-3.897-1.323-4.404-2.825-1.098 1.976-3.16 2.825-5.371 2.825-3.046 0-5.109-2.009-5.109-4.769 0-3.217 2.309-5.454 7.647-6.744l2.456-.588v-3.298c0-2.058-.704-2.89-1.949-2.89-1.178 0-1.948.767-1.948 1.828 0 .915.442 1.502 1.212 2.237 0 1.176-1.834 2.384-3.75 2.384-1.867 0-3.193-1.437-3.193-3.184 0-3.119 3.455-5.307 8.53-5.307 5.29 0 8.09 2.237 8.09 7.218v7.87c0 .997.589 1.65 1.506 1.65.54 0 .95-.114 1.244-.326zm-9.742-.555v-5.895l-.475.147c-1.572.474-2.653 1.503-2.653 3.56 0 1.764.704 2.825 1.834 2.825.606-.016 1.081-.196 1.294-.637z" />
          <path d="M115.731 26.749h-9.89c1.031-.996 1.686-2.45 1.686-5.455V15.35c0-2.008-.262-3.037-1.473-4.18l-.852-.768 9.104-3.445v2.857c1.064-1.763 3.308-2.857 5.551-2.857 3.52 0 5.469 1.91 5.469 5.307v10.271c0 2.09.556 3.364 1.441 4.214h-9.628c.949-.915 1.211-2.156 1.211-3.152V13.113c0-1.535-.507-2.303-1.948-2.303-.802 0-1.474.294-1.916.735v12.052c.033.996.279 2.204 1.245 3.152zM128.584 25.687v-6.27c2.653 3.445 5.403 5.356 7.974 5.356 1.245 0 1.834-.654 1.834-1.568 0-.882-.475-1.388-1.326-1.715l-4.258-1.567c-3.274-1.209-4.732-3.217-4.732-6.222 0-3.887 3.193-6.712 7.745-6.712 2.456 0 5.174.702 6.533 1.682v5.357c-1.686-2.973-4.453-4.622-7.122-4.622-1.359 0-2.063.441-2.063 1.29 0 .85.507 1.176 1.653 1.65l5.044 2.024c2.538 1.03 3.667 3.185 3.667 5.798 0 4.294-3.127 7.152-7.826 7.152-2.342-.016-4.765-.539-7.123-1.633zM144.27 9.34l2.129-3.265c2.718-4.1 4.896-6.075 8.564-6.075 2.865 0 4.699 1.241 4.699 3.119 0 1.617-1.326 2.792-4.11 2.792 0-2.27-1.294-3.119-2.571-3.119-1.293 0-2.276.915-2.276 2.417 0 1.03.557 2.058 2.244 2.303h4.077l-1.474 1.829h-2.505v10.876c0 2.057.327 3.886 2.456 5.748l.884.767h-12.673c1.473-1.47 2.357-3.266 2.357-5.895V9.341h-1.801z" />
          <path d="M173.072 20.527c-1.179 5.095-4.011 6.777-8.121 6.777-4.699 0-9.104-3.413-9.104-9.815 0-6.156 3.897-10.516 9.006-10.516 3.34 0 8.039 1.715 8.039 8.981h-11.101c.769 3.887 3.307 5.895 6.828 5.895 1.817-.016 3.061-.342 4.453-1.322zm-11.494-7.12v.473h4.846c0-3.657-.802-4.8-2.357-4.8-1.572 0-2.489 1.584-2.489 4.327zM185.385 26.749h-11.691c1.441-1.682 2.063-2.793 2.063-5.455V15.35c0-2.09-.294-3.07-1.686-4.36l-.802-.735 8.825-3.298v4.572c.852-2.645 2.161-4.572 4.339-4.572 1.769 0 2.898 1.24 2.898 3.07 0 1.943-1.211 3.298-2.898 3.298-.36-.849-1.244-1.24-2.063-1.24-.655 0-1.293.179-1.621.505v8.476c-.016 2.825.459 3.592 2.636 5.683z" />
        </g>
      </svg>
    </ExternalLink>
  )
}
