/** @jsx jsx */
import { jsx } from 'theme-ui'

export const Logo = (props) => {
  return (
    <svg fill="none" viewBox="0 0 2775 1943" alt="RadioRadio" {...props}>
      <g
        clipPath="url(#clip0)"
        fill="var(--main-color)"
        sx={{ transition: 'fill 1s ease' }}
      >
        <path d="M2128.9 1271.1h458.3l-342.3 166.3v164.8l337 165.3h-453v174.9h644.5v-265.8l-308.5-163.4 308.5-147.3v-264.5h-644.5v169.7zM1873.5 0c22.3 15.1 43.6 31.4 63.8 49 49.2 42.7 90.5 91.3 123.5 145h189.3v514.1h180.1V194h164.3v770.7h180.1V0h-901.1zM194.2 943.9v-763h308.4V.5H0v1942.3h194.2v-818.5h308.4V943.9H194.2zM1342.1 311.6h-228.9c51.8 60.2 79.6 139.7 79.6 235.5 0 108.6-40.6 199.1-105.2 266.1h216.6c52.7-80 82.8-179.8 82.8-293.8.1-75-15.8-145-44.9-207.8zM1359.3 1255h-194.2v687.8h194.2V1255z" />
        <path d="M1851.6 147.7C1742.3 52.8 1591.5.5 1426.7.5H633.3v1942.3h194.2v-818.5h846.1c41.4 0 72.9 10.5 93.6 31.2 20.7 20.7 31.2 52.2 31.2 93.7v693.7h194.2v-693.7c0-93.9-17.7-157.3-55.8-199.4-15.7-17.3-34.3-30.7-57.1-40.8l-91.8-41 74.7-67.3c49.7-44.8 88.6-99.7 115.5-163.1 28.1-66.1 42.3-139.5 42.3-218.1 0-74.3-15.2-144.6-45-208.8-28.8-61.9-70.4-116.7-123.8-163zm-273.5 58c52.4 16.3 97.6 40.5 134.3 71.9 36.9 31.5 65.3 70.2 84.4 115.1 19.5 45.6 29.3 97.5 29.3 154.4 0 113.4-46.2 216.3-130.2 289.8-78.8 69-184.3 107-297 107H827.5v-763H1399c65.9 0 126.2 8.3 179.1 24.8z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            sx={{ transition: 'fill 1s ease' }}
            fill="var(--main-color)"
            d="M0 0h2774.6v1942.8H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
