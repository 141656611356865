/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'

import Header from '@components/Header'
import Footer from '@components/Footer'
import ActionCableProvider from '@utils/actionCable'

const Layout = ({ children, hideFooter = false, ...rest }) => {
  return (
    <ActionCableProvider>
      <Grid
        sx={{
          gap: 0,
          minHeight: '100vh',
          gridTemplateRows: hideFooter
            ? `max-content 1fr`
            : `max-content 1fr max-content`,
          gridTemplateColumns: '1fr',
          bg: 'var(--main-color)',
          transition: 'background 1s ease',
        }}
        {...rest}
      >
        <Header />
        <Grid as="main" gap={0}>
          {children}
        </Grid>
        {!hideFooter && <Footer />}
      </Grid>
    </ActionCableProvider>
  )
}

export default Layout
